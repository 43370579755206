import { BusinessApplication } from "./businessApplication";
import Drawdown from "./drawdown";

export default interface FormR {
  formRID: string;
  amount: number;
  name: string;
  address: string;
  repaymentPeriod: number;
  periodType: RepaymentPeriodType;
  arrangementFee: number;
  guaranteePercentage: number;
  dateOfApplication: Date;
  otherSpecification: string;
  purposeOfFacility: string;
  industryType: string;
  repaymentType: RepaymentType;
  rulingPrimeRate: number;
  interestRate: number;
  securityType: SecurityType;
  nameOfBeneficialOwner: string;
  adressOfBeneficialOwner: string;
  description: string;
  approverID: string;
  approvedDate: Date;
  repaymentCommencement: number;
  businessApplicationID: string;
  businessApplication?: BusinessApplication;
  drawdowns: Drawdown[];
  status: FormRStatus;
}

export enum RepaymentType {
  Equal,
  Monthly,
  Quarterly,
  BiAnnual,
}
export enum SecurityType {
  Bond,
  Other,
}

export enum FormRStatus {
  Submitted,
  Rejected,
  Approved,
}

export enum RepaymentPeriodType {
  Weeks,
  Months,
  Years,
}
