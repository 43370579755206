










































































































































































































































































































































































































































































































































































































































































































































import Vue from "vue";
import { Component, Watch } from "vue-property-decorator";
import { Money } from "v-money";
import FormR, {
  FormRStatus,
  RepaymentPeriodType,
  SecurityType,
} from "@/models/formR";
import {
  ApplicationStatus,
  BusinessProfileVM,
} from "@/models/businessApplication";
import DrawdownComponent from "@/components/Drawdown.vue";
import agent from "@/helpers/agent";
import { v4 as uuid } from "uuid";
import Notifier from "@/helpers/notifier";
import QForm from 'quasar/src/components/form/QForm.js';import QInput from 'quasar/src/components/input/QInput.js';;
import Drawdown, { DrawdownAction } from "@/models/drawdown";
import { AppEventBus, AppEventBusEvents } from "@/helpers/event_bus";
import { CommentType } from "@/models/comment";
import Comments from "@/components/Comments.vue";
import RejectFormR from "@/components/RejectFormR.vue";

@Component({ components: { DrawdownComponent, Comments, Money, RejectFormR } })
export default class FormRView extends Vue {
  step = 1;
  formR: FormR = {
    formRID: "",
    amount: 0,
    name: "",
    address: "",
    repaymentPeriod: 0,
    periodType: RepaymentPeriodType.Weeks,
    arrangementFee: 0,
    guaranteePercentage: 0,
    dateOfApplication: new Date(),
    otherSpecification: "",
    purposeOfFacility: "",
    industryType: "",
    repaymentType: 0,
    rulingPrimeRate: 0,
    interestRate: 0,
    securityType: SecurityType.Bond,
    nameOfBeneficialOwner: "",
    adressOfBeneficialOwner: "",
    description: "",
    approverID: "",
    approvedDate: new Date(),
    repaymentCommencement: 0,
    businessApplicationID: "",
    drawdowns: [],
    status: FormRStatus.Submitted,
  };
  moneyFormatForComponent: any = {
    decimal: ".",
    thousands: ",",
    prefix: "E ",
    suffix: "",
    precision: 2,
    masked: true,
  };
  drawdownInstallment = 1;
  total = 0;
  showDialog = false;
  commentType = CommentType.Document;
  businessProfile: BusinessProfileVM = {
    id: "",
    businessName: "",
    type: 0,
    reference: "",
    status: ApplicationStatus.Pending,
    legalProfileStatus: "",
    businessProfileStatus: "",
    formRStatus: "",
    formJStatus: "",
    bankingProfileStatus: "",
    additionalProfileStatus: "",
    isIndividual: true,
  };
  loading = false;
  initFlag = true;
  validateDrawdownsTotal = false;
  showRejectDialog = false;
  industrialClassification = 0;
  industrialClassificationTypes = [
    {
      label: "Retail",
      value: 0,
    },
    {
      label: "Services",
      value: 1,
    },
    {
      label: "Manufacturing",
      value: 2,
    },
    {
      label: "Agriculture",
      value: 3,
    },
    {
      label: "Transport",
      value: 4,
    },
    {
      label: "Other",
      value: 5,
    },
  ];
  repaymentPeriodTypes = [
    {
      label: "Weeks",
      value: 0,
    },
    {
      label: "Months",
      value: 1,
    },
    {
      label: "Years",
      value: 2,
    },
  ];
  repaymentTypes = [
    {
      label: "Equal",
      value: 0,
    },
    {
      label: "Monthly",
      value: 1,
    },
    {
      label: "Quartely",
      value: 2,
    },
    {
      label: "Bi-Annual",
      value: 3,
    },
  ];
  securityTypes = [
    {
      label: "Bond",
      value: 0,
    },
    {
      label: "Other",
      value: 1,
    },
  ];
  guaranteePercentageTypes = [
    {
      label: "85%",
      value: 85,
    },
    {
      label: "95%",
      value: 95,
    },
    {
      label: "98%",
      value: 98,
    },
  ];
  get readonly() {
    return (
      this.businessProfile.status ===
        ApplicationStatus.SubmittedToCentralBank ||
      this.businessProfile.status === ApplicationStatus.Reviewed ||
      this.businessProfile.status === ApplicationStatus.Approved
    );
  }
  get drawdownAction() {
    if (this.formR.formRID.length > 0) return DrawdownAction.Update;

    return DrawdownAction.Create;
  }
  get drawDownsTotal() {
    return this.formR.drawdowns.reduce((acc, curr) => curr.amount + acc, 0);
  }
  get isValidDrawdowns() {
    return this.formR.amount !== this.drawDownsTotal;
  }
  get showBeneficialOwnerDetails() {
    return this.formR.securityType === SecurityType.Bond;
  }
  emitMyValue(s: any) {
    console.log(s);
  }
  @Watch("drawdownInstallment")
  onPropertyChanged(value: number, oldValue: number) {
    if (this.drawdownInstallment === this.formR.drawdowns.length) return;

    if (value > oldValue) {
      this.formR.drawdowns.push({
        formRID: this.formR.formRID,
        drawdownID: uuid(),
        amount: 0,
        monthsAfterInitial: 0,
      });
      return;
    }
    this.formR.drawdowns.pop();
  }
  @Watch("industrialClassification")
  onIndustrialChang(value: number) {
    if (value !== 5) {
      this.formR.industryType = this.industrialClassificationTypes[value].label;
      return;
    }
  }
  deleteFormR(id: string) {
    agent.FormR.delete(id).then(() => {
      Notifier.showPositive("Successfully deleted Form R");
      this.$router.push({
        name: "ProfilesApplication",
        query: { ...this.$route.query },
      });
    });
  }
  goToNextStep(formRef: string) {
    // if readonly do not perform any validations
    if (this.formR.status === FormRStatus.Rejected) {
      this.onSubmit();
      this.$router.go(-1);
    }
    if (this.readonly) {
      if (this.step < 3) {
        this.step++;
      } else {
        this.$router.go(-1);
      }
      return;
    }

    const form = this.$refs[formRef] as QForm;
    form.validate().then((success: boolean) => {
      if (success) {
        // yay, models are correct
        switch (this.step) {
          case 3:
            this.onSubmit();
            break;
          default:
            this.step++;
            break;
        }
      } else {
        // oh no, user has filled in
        // at least one invalid value
        Notifier.showError(
          `There are validation errors, please make sure you have filled all the required fields`
        );
      }
    });
  }
  onSubmit() {
    if (this.formR.formRID.length === 0) {
      const newFormId = uuid();
      const drawdowns = this.formR.drawdowns.map((i) => {
        return {
          ...i,
          drawdownID: uuid(),
          formRID: newFormId,
        };
      });
      agent.FormR.create({
        ...this.formR,
        formRID: newFormId,
        businessApplicationID: this.$route.query.id as string,
        drawdowns: drawdowns,
      }).then(() => {
        Notifier.showPositive("Successfully submitted Form R");
        this.$router.push({
          name: "ProfilesApplication",
          query: { ...this.$route.query },
        });
      });
    } else {
      agent.FormR.update(this.formR).then(() => {
        Notifier.showPositive("Successfully updated Form R");
        this.$router.push({
          name: "ProfilesApplication",
          query: { ...this.$route.query },
        });
      });
    }
  }
  created() {
    AppEventBus.$on(AppEventBusEvents.drawdownEvent, (drawdown: Drawdown) => {
      this.formR.drawdowns[drawdown?.index as number] = drawdown;
      this.validateDrawdownsTotal = true;
      const form = this.$refs["amount"] as QInput;
      form.validate();
    });
  }
  mounted() {
    this.formR.drawdowns = [
      {
        formRID: this.formR.formRID,
        drawdownID: uuid(),
        amount: 0,
        monthsAfterInitial: 0,
      },
    ];
    agent.FormR.details(this.$route.query.id as string).then((formR) => {
      if (formR.formRID) {
        const classification = this.industrialClassificationTypes.find(
          (x) => x.label === formR.industryType
        );
        if (classification) {
          this.industrialClassification = classification?.value;
        } else {
          this.industrialClassification = 5;
        }
        this.formR = formR;
        this.drawdownInstallment = this.formR.drawdowns.length;
        this.initFlag = false;
        this.showRejectDialog = this.$route.query.showRejection === "1";
        this.total = this.formR.rulingPrimeRate + this.formR.interestRate;
        agent.Applications.getProfile(this.$route.query.id as string).then(
          (businessProfile) => {
            this.businessProfile = businessProfile;
          }
        );
      }
    });
  }
  beforeDestroy() {
    AppEventBus.$off(AppEventBusEvents.drawdownEvent);
  }
}
